import { Router } from 'react-router-dom';
import { ErrorBoundary, Spinner } from 'components';
import { ModalContainer } from 'shared/modal/ModalContainer';

import AppInitializer from './AppInitializer';
import AppProviders from './AppProviders';
import history from './history';
import Routes from './routes';

const App = () => (
  <ErrorBoundary>
    <Router history={history}>
      <AppProviders>
        <AppInitializer placeholder={<Spinner />}>
          <Routes />
          <ModalContainer />
        </AppInitializer>
      </AppProviders>
    </Router>
  </ErrorBoundary>
);

export default App;
